import React, { useMemo } from 'react';

import ScheduleBlock from 'components/schedule-block/schedule-block';

import { format } from 'date-fns';
import getRandomColor from '../../helpers/get-random-color';
import getHours from '../../helpers/get-hours';
import _ from 'lodash';
import parseDate from 'helpers/parseDate';
import getArrayFromDates from 'helpers/get-array-from-dates';
import moment from 'moment';

const isCurrent = (startTime, endTime) => {
  const currentMinuteOfTheDay = moment().hour() * 60 + moment().minutes();
  return startTime <= currentMinuteOfTheDay && endTime >= currentMinuteOfTheDay;
};

const ScheduleMobile = ({ scheduleData, device }) => {
  const currentSchedule = useMemo(() => {
    const localScheduleData = scheduleData?.map((schedule) => {
      schedule.playingPlaylists = [...schedule.playingPlaylists].map((item) =>
        Object.assign({ color: getRandomColor(_.random(14)) }, item)
      );
      const todayDate = new Date();

      const daysArray = getArrayFromDates(
        todayDate,
        parseDate(schedule.endDate)
      )
        .slice(0, 20)
        .map((date) => {
          const formattedDate = format(date, 'EEEE do MMMM');

          return {
            day: date.getDay(),
            date,
            formattedDate,
            playlists: schedule.playingPlaylists
              .filter((playlist) => {
                const dayOfTheWeek = date.getDay() > 0 ? date.getDay() - 1 : 6;
                return playlist?.days[dayOfTheWeek];
              })
              .map((playlist) => {
                return {
                  ...playlist,
                  timeSpan: getHours(playlist.startTime, playlist.endTime),
                };
              }),
          };
        });

      return {
        ...schedule,
        remainingDays: daysArray,
      };
    });

    const schedule = localScheduleData?.find(
      (item) => device?.scheduleIds?.length > 0 && item.id === device?.scheduleIds[0]
    );

    return {
      ...schedule,
      // remainingDays: schedule?.remainingDays.filter((scheduleDay) => {
      //   return scheduleDay.playlists.some(
      //     (playlist) => playlist.days[scheduleDay.day]
      //   );
      // }),
    };
  }, [scheduleData, device?.scheduleIds]);

  return (
    <div style={{ padding: '0px 13px', marginBottom: 135 }}>
      {currentSchedule?.remainingDays?.map((scheduleDay, index) => (
        <div className="schedule__day" key={scheduleDay.date}>
          <h5>{scheduleDay.formattedDate}</h5>

          <div className="schedule__day-blocks" style={{ maxHeight: '100%' }}>
            {scheduleDay.playlists
              .sort((a, b) => {
                if (a?.startTimeSeconds < b?.startTimeSeconds) {
                  return -1;
                } else if (a?.startTimeSeconds > b?.startTimeSeconds) {
                  return 1;
                }
                return 0;
              })
              .map((playlist) => {
                let currentPlaylist = false;
                if (index === 0) {
                  currentPlaylist = isCurrent(
                    playlist.startTime,
                    playlist.endTime
                  );
                }
                return (
                  <ScheduleBlock
                    isCurrent={currentPlaylist}
                    key={
                      playlist?.startTime + playlist?.endTime + playlist?.name
                    }
                    timeSpan={playlist?.timeSpan}
                    title={playlist?.name}
                    color={playlist?.color}
                    description={playlist?.description}
                  />
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScheduleMobile;
