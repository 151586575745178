import React from 'react';
import Modal from 'components/modal/modal';
import Button from 'components/button/button';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, coreName }) => {
  return (
    <Modal
      subtitle="Curator for Q-SYS"
      title="Delete Core"
      visible={isOpen}
      onClose={onClose}
    >
      <p>Are you sure you want to delete "{coreName}"?</p>
      <p style={{ color: '#ff4d4d', marginTop: 10 }}>
        The core will be deleted for all associated users. This action cannot be
        undone.
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
        }}
      >
        <Button modifier="primary" onClick={onConfirm}>
          Delete
        </Button>
        <Button
          modifier="as-link"
          onClick={onClose}
          style={{ padding: '0px 30px' }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
