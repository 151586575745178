/**
 * External dependencies
 */
import React, { useState } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import Button from 'components/button/button';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useSearchPlaylists from '../../music/hooks/use-search-playlists';
import useAuth from '../../auth/hooks/use-auth';
import useTriggerQsysAction from '../hooks/use-trigger-qsys-action';

const MusicSearchPage = ({ id, searchTerm }) => {
  const { user, currentDevice } = useAuth();
  const [sections, setAllSections] = useState([
    {
      name: 'Playlists',
      expanded: false,
    },
    {
      name: 'Scenes',
      expanded: false,
    },
  ]);
  const { triggerQsysAction } = useTriggerQsysAction();

  const { searchedPlaylists, searchedScenes, loading } = useSearchPlaylists({
    text: searchTerm,
    currentDevice: user?.defaultDeviceId,
    currentClient: user?.profile?.clientId,
  });

  const handleViewAll = (section) => {
    let tempSections = [...sections];
    tempSections[section] = {
      ...tempSections[section],
      expanded: true,
    };
    setAllSections(tempSections);
  };

  const handlePlayNowClick = (item, type) => {
    triggerQsysAction({
      variables: {
        deviceId: currentDevice,
        zoneId: Number(id),
        actions: {
          mixId: item.id,
          mixType: type,
        },
      },
    });
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={`‘${searchTerm}’`}
      modifier="music-search"
      style={{ marginTop: 10 }}
    >
      <LoadingProvider loading={loading}>
        {!searchedPlaylists?.length && !searchedScenes?.length && (
          <h2>Nothing found. Try something else</h2>
        )}

        {searchedPlaylists?.length > 0 && (
          <div
            style={{
              marginBottom: searchedScenes.length ? 30 : 135,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20,
                padding: '0px 13px',
              }}
            >
              <h1 style={{ fontSize: 13 }}>Playlists</h1>
              <h1 style={{ fontSize: 13, fontWeight: 300, color: '#3BABA1' }}>
                {!sections[0].expanded && (
                  <Button onClick={() => handleViewAll(0)} modifier="as-link">
                    View all
                  </Button>
                )}
              </h1>
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'auto',
                flexDirection: sections[0].expanded ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {searchedPlaylists
                ?.slice(0, sections[0].expanded ? searchedPlaylists.length : 4)
                .map((playlist, index) => {
                  return (
                    <CardLink
                      key={playlist?.id}
                      to={`/qsys/core/${currentDevice}/zone/${id}/search/playlists/${playlist?.id}`}
                      title={playlist?.name}
                      cover={playlist?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: sections[0].expanded ? 0 : 13,
                        marginRight: !sections[0].expanded ? 7 : 0,
                        minWidth: 225,
                        marginBottom: 20,
                      }}
                      hasButton
                      handleClicked={() =>
                        handlePlayNowClick(playlist, 'playlist')
                      }
                    />
                  );
                })}
            </div>
          </div>
        )}
        {searchedScenes?.length > 0 && (
          <div style={{ marginBottom: 135 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20,
                padding: '0px 13px',
              }}
            >
              <h1 style={{ fontSize: 13 }}>Scenes</h1>
              <h1 style={{ fontSize: 13, fontWeight: 300, color: '#3BABA1' }}>
                {!sections[1].expanded && (
                  <Button onClick={() => handleViewAll(1)} modifier="as-link">
                    View all
                  </Button>
                )}
              </h1>
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'auto',
                flexDirection: sections[1].expanded ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {searchedScenes
                ?.slice(0, sections[1].expanded ? searchedPlaylists.length : 4)
                .map((scene, index) => {
                  return (
                    <CardLink
                      key={scene?.id}
                      to={`/qsys/core/${currentDevice}/zone/${id}/search/all/scene/${scene.id}`}
                      title={scene?.name}
                      cover={scene?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: sections[1].expanded ? 0 : 13,
                        marginRight: !sections[1].expanded ? 7 : 0,
                        minWidth: 225,
                        marginBottom: 20,
                      }}
                      hasButton
                      handleClicked={() => handlePlayNowClick(scene, 'scene')}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default MusicSearchPage;
