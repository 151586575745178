/**
 * External dependencies
 */
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import _ from 'lodash';

/**
 * Internal dependencies
 */
import AuthRoute from 'blocks/auth-route/auth-route';
import GuestRoute from 'blocks/guest-route/guest-route';
import SonosRoute from 'blocks/sonos-route/sonos-route';
import QsysRoute from 'blocks/qsys-route.js/qsys-route';

import useAuth from 'domain/auth/hooks/use-auth';

/**
 * Pages
 */
import LoginPage from 'views/auth/login/login';
import DashboardRoutes from 'domain/dashboard/routes/dashboard-routes';
import CurrentPlaylistPage from 'domain/music/pages/current-playlist';
import ProfilePage from 'views/profile/profile';
import AccountPage from 'views/profile/account';
import SupportPage from 'views/support/support';
import DevicesRoutes from 'domain/devices/routes/devices-routes';
import HighlightRoutes from 'domain/highlight/routes/highlight-routes';
import MusicRoutes from 'domain/music/routes/music-routes';
import SonosRoutes from 'domain/sonos/routes/sonos-routes';
import QsysRoutes from 'domain/qsys/routes/qsys-routes';
import useUser from 'domain/auth/hooks/use-user';
import useSonosAuthToken from 'domain/auth/hooks/use-sonos-auth-token';
import useUsersSubscription from 'domain/auth/hooks/use-users-subscriptions';
import useSignOutClear from '../domain/auth/hooks/use-signout-clear';
import { notify } from '../utils/notify-toast';
import OktaWrapper from '../utils/app-start/okta-wrapper';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaCallback from '../utils/app-start/okta-callback';
import { error } from 'mapbox-gl/src/style-spec/util/result';
import useRefreshToken from '../domain/auth/hooks/use-refresh-token';

/**
 * Function to get the URL query parameters
 * @returns {URLSearchParams} The URL query parameters
 */
function useQuery() {
  return new window.URLSearchParams(useLocation().search);
}

/**
 * The Routes component handles the routing for the application.
 * It uses different types of routes (AuthRoute, GuestRoute, SonosRoute, QsysRoute) to control access to different parts of the application.
 * It also handles user authentication and subscription updates.
 */

const Routes = () => {
  let query = useQuery();
  const {
    user,
    setUser,
    isLoggedIn,
    token,
    logoutSuccess,
    decodedToken,
    setSessionToken,
  } = useAuth();
  const { data: userQuery, loading: loadingUserQuery } = useUser();
  const { getSonosAuthToken } = useSonosAuthToken();

  const [sonosAuth, setSonosAuth] = useState(false);
  const [redirectUrl] = useState(query.get('redirectUrl'));
  const [linkCode] = useState(query.get('linkCode'));
  const [jwtToken, setJwtToken] = useState(query.get('jwtToken'));
  const [sonosSuccess, setSonosSuccess] = useState(
    query.get('sonosSuccess') && query.get('sonosSuccess') !== 'false'
  );
  const [errorDesc, setErrorDesc] = useState(query.get('errorDesc'));
  const [oktaClientId] = useState(
    query.get('clientId') || localStorage.getItem('oktaClientId')
  );
  const [iss] = useState(
    query.get('iss') || localStorage.getItem('oktaDomain')
  );
  query.get('clientId') && query.get('iss') && localStorage.clear();
  oktaClientId && localStorage.setItem('oktaClientId', oktaClientId);
  iss && localStorage.setItem('oktaDomain', iss);
  const oktaAuth = useMemo(
    () =>
      oktaClientId && iss
        ? new OktaAuth({
            clientId: oktaClientId,
            issuer: iss,
            redirectUri: `${window.location.origin}${process.env.REACT_APP_OKTA_REDIRECT_URL}`,
            scopes: ['openid', 'profile', 'email'],
            pkce: true,
          })
        : null,
    [oktaClientId, iss] // will create new object only if one of these changes
  );

  const { userSubscription, userSubscriptionLoading } = useUsersSubscription({
    token,
    updatedId: user?.id,
  });

  const { signOutClear } = useSignOutClear();

  const { refreshToken } = useRefreshToken();

  useEffect(() => {
    if (user?.status?.curatorSignOut) {
      signOutClear()
        .then(() => {
          logoutSuccess();
        })
        .catch((e) => {
          console.log('SignOutClear error:', e);
        });
    }

    if (!userSubscriptionLoading && user) {
      const updatedUser = userSubscription?.UserSubscription?.node;
      if (
        userSubscription?.UserSubscription?.mutation === 'DELETED' &&
        user?.id === updatedUser?.id
      ) {
        logoutSuccess();
      }
      if (
        !_.isEqual(user?.profile, updatedUser?.profile) &&
        user?.id === updatedUser?.id
      ) {
        setUser(updatedUser);
      }
      //check if qsys trial ended and update user object
      if (
        !_.isEqual(user?.qsys, updatedUser?.qsys) &&
        user?.id === updatedUser?.id
      ) {
        setUser(updatedUser);
      }

      if (
        !_.isEqual(user?.stripe, updatedUser?.stripe) &&
        user?.id === updatedUser?.id
      ) {
        setUser(updatedUser);
      }
      if (
        !_.isEqual(user?.sonos, updatedUser?.sonos) &&
        user?.id === updatedUser?.id
      ) {
        setUser(updatedUser);
      }
      if (sonosSuccess) {
        if (updatedUser?.sonos?.authProcessStatusDone) {
          notify('You may return to the Sonos app to finish the setup.', {
            redirect: true,
          });
          setSonosSuccess(false);
        }
      }
    }
  }, [userSubscription]);

  if (isLoggedIn && jwtToken && linkCode && redirectUrl) {
    setJwtToken(null);
    setSonosAuth(true);
    getSonosAuthToken({
      variables: {
        jwtToken: (redirectUrl && linkCode && jwtToken) || '',
      },
    })
      .then(({ data }) => {
        const { getSonosAuthToken } = data;
        console.log('data', data);
        if (
          getSonosAuthToken?.sonosAuthObj?.authToken &&
          getSonosAuthToken?.sonosAuthObj?.message === 'OK' &&
          redirectUrl &&
          linkCode
        ) {
          window.location.href = `${redirectUrl}?linkCode=${linkCode}&authToken=${data?.getSonosAuthToken.sonosAuthObj.authToken}`;
        }
      })
      .catch((err) => {
        throw error('Sonos login failed!', err);
      });
  }

  useEffect(() => {
    if (sonosSuccess) {
      notify(
        'Please wait for the setup to complete. Enabling Activaire Music Service account on Sonos.'
      );
    } else if (errorDesc) {
      notify(errorDesc);
      setErrorDesc(false);
      setSonosSuccess(false);
    }
  }, [sonosSuccess, errorDesc]);

  useEffect(() => {
    if (!user && !loadingUserQuery) {
      setUser(userQuery);
    }

    // compare roles from the token and roles from DB
    if (user && decodedToken) {
      const tokenRoleAccess = decodedToken?.roleAccess;
      const tokenRoleAudio = decodedToken?.roleAudio;
      const dbRoleAccess = user?.rolesConsole?.roleAccess;
      const dbRoleAudio = user?.rolesConsole?.roleAudio;

      // refetch auth token if roles are different
      if (tokenRoleAccess !== dbRoleAccess || tokenRoleAudio !== dbRoleAudio) {
        refreshToken().then((res) => {
          if (res?.data?.refreshToken) {
            setSessionToken(res?.data?.refreshToken);
            window.location.reload();
          }
        });
      }
    }
  }, [loadingUserQuery, jwtToken]);

  const sonosOnly =
    user?.serviceType?.includes('SONOS') &&
    !user?.serviceType?.includes('CURATOR') &&
    !user?.serviceType?.includes('QSYS');

  const qsysOnly =
    user?.serviceType?.includes('QSYS') &&
    !user?.serviceType?.includes('CURATOR') &&
    !user?.serviceType?.includes('SONOS');

  const hasSonos = user?.serviceType?.includes('SONOS');
  const hasCurator = user?.serviceType?.includes('CURATOR');
  const hasQsys = user?.serviceType?.includes('QSYS');

  const oktaLogin = async () => {
    await oktaAuth?.signInWithRedirect();
  };
  // Okta login url looks like: https://curator.activaire.com/login/okta-initiate?clientId=[clientId]&iss=[iss]
  return (
    <OktaWrapper oktaAuth={oktaAuth} style={{ display: 'none' }}>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <Route
          exact
          path="/login/okta-initiate"
          render={() => {
            oktaLogin()
              .then(() => {
                console.log('Okta login successful');
              })
              .catch((err) => {
                // log the errorred domain
                console.error('Okta login failed for domain:', window?.location?.href);
                throw Error('Okta login failed!', err);
              });
            return <></>;
          }}
        />
        <GuestRoute exact path="/login/okta" component={OktaCallback} />

        {hasQsys && <QsysRoute path="/qsys" component={QsysRoutes} />}
        {qsysOnly && <Redirect exact to="/qsys" />}

        <AuthRoute path="/:id?/profile/account" component={AccountPage} />
        <AuthRoute path="/:id?/profile" component={ProfilePage} />
        {(hasCurator || hasSonos) && (
          <AuthRoute path="/:id?/support" component={SupportPage} />
        )}
        {(hasCurator || hasSonos) && (
          <AuthRoute path="/:id?/highlights" component={HighlightRoutes} />
        )}
        {hasSonos && <SonosRoute path="/sonos" component={SonosRoutes} />}
        {(sonosOnly || sonosAuth) && <Redirect exact to="/sonos" />}

        {hasCurator && <AuthRoute path="/:id/music" component={MusicRoutes} />}
        {hasCurator && (
          <AuthRoute
            path="/:id/dashboard/current-playlist"
            component={CurrentPlaylistPage}
          />
        )}

        {hasCurator && (
          <AuthRoute path="/:id/dashboard" component={DashboardRoutes} />
        )}
        <AuthRoute exact path="/">
          {user?.defaultServiceType === 'SONOS' && hasSonos && (
            <Redirect to="/sonos" />
          )}
          {user?.defaultServiceType === 'QSYS' && hasQsys && (
            <Redirect to="/qsys" />
          )}
          <AuthRoute exact path="/" component={DevicesRoutes} />
        </AuthRoute>

        {hasCurator && <AuthRoute path="/:id" component={MusicRoutes} />}
        {!isLoggedIn && <Redirect to="/login" />}
      </Switch>
    </OktaWrapper>
  );
};

export default Routes;
