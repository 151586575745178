/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { TRIGGER_QSYS_ACTION } from '../mutations/trigger-qsys-action';

/**
 * Internal dependencies
 */

export default function useTriggerQsysAction() {
  const [triggerQsysAction] = useMutation(TRIGGER_QSYS_ACTION);

  return {
    triggerQsysAction: ({
      variables: { deviceId: coreId, zoneId, actions },
    }) => {
      return triggerQsysAction({
        variables: {
          deviceId: coreId,
          zoneId: Number(zoneId),
          actions,
        },
      });
    },
  };
}
