import React, { useState, useEffect } from 'react';
import * as rdd from 'react-device-detect';
import Marquee from 'react-fast-marquee';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { ReactComponent as IcoDevicesColor } from 'assets/svg-icons/ico-devices-color.svg';
import getRandomArtwork from '../../../helpers/get-random-artwork';

import PauseSvg from 'assets/svgComponents/PauseSvg';
import SkipSvg from 'assets/svgComponents/SkipSvg';
import PlaySvg from 'assets/svgComponents/PlaySvg';
import _ from 'lodash';

import useAllQsysDevices from '../hooks/use-all-qsys-devices';
import useTriggerQsysAction from '../hooks/use-trigger-qsys-action';
import useQsysDeviceSubscription from '../hooks/use-qsys-device-subscription';
import VolumeSvg from '../../../assets/svgComponents/VolumeSvg';
import ProgressRangeFooter from '../../../components/volume-slider/volume-slider-footer-qsys';
import useAuth from 'domain/auth/hooks/use-auth';

const NowPlayingWrapper = ({ children }) => {
  const {
    path,
    params: { id, coreId },
  } = useRouteMatch();
  const { user } = useAuth();
  const { allQsysDevices } = useAllQsysDevices();

  const [zone, setZone] = useState(
    allQsysDevices
      ?.find((device) => device.id === coreId)
      ?.zones?.find((zone) => zone?.zoneId.toString() === id)
  );

  const { triggerQsysAction } = useTriggerQsysAction();

  const { qsysDevicesSubscription, qsysDevicesSubscriptionLoading } =
    useQsysDeviceSubscription();

  useEffect(() => {
    if (!qsysDevicesSubscriptionLoading) {
      const getZones =
        qsysDevicesSubscription?.QsysDeviceSubscription?.node?.zones || [];
      if (
        qsysDevicesSubscription?.QsysDeviceSubscription?.node?.id === coreId
      ) {
        const actualZone = getZones.find((item) => item.zoneId === Number(id));
        setZone(actualZone);
      }
    }
  }, [qsysDevicesSubscription]);

  const history = useHistory();
  const [randomArtwork, setRandomArtwork] = useState();

  useEffect(() => {
    setRandomArtwork(getRandomArtwork());
  }, []);

  const handleReason = (reason) => {
    triggerQsysAction({
      variables: {
        deviceId: coreId,
        zoneId: Number(id),
        actions: {
          reason,
        },
      },
    });
  };

  return (
    <>
      {id &&
        (!rdd?.isMobileOnly ? (
          <div
            className="now-playing"
            style={{
              backgroundColor:
                path.includes('highlights') ||
                (path.includes('dashboard') &&
                  !history.location.pathname.includes('schedule') &&
                  !history.location.pathname.includes('information'))
                  ? '#000'
                  : '#393b5a',
            }}
          >
            <img alt="" src={zone?.currentArtwork || randomArtwork} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <div
                className="now-playing__metadata"
                onClick={() =>
                  history.push(`/qsys/core/${coreId}/zone/${id}/dashboard`)
                }
              >
                <>
                  <h3>{zone?.currentTitle}</h3>
                  <p>
                    {_.capitalize(zone?.mixType)}: {zone?.mixName}
                  </p>
                </>
                <p
                  style={{
                    display: 'flex',
                    color: '#3BABA1',
                    marginTop: 10,
                  }}
                >
                  <IcoDevicesColor
                    style={{ height: 16, width: 16, marginRight: 5 }}
                  />
                  {zone?.zoneName}
                </p>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <>
                  {user?.qsys?.hideVolumeBar ? null : (
                    <>
                      <VolumeSvg />
                      <ProgressRangeFooter
                        deviceId={coreId}
                        volume={zone?.volume || 0}
                        zoneId={Number(id)}
                      />
                    </>
                  )}
                  {zone?.reason === 'pause' ? (
                    <PlaySvg onClick={() => handleReason('play')} />
                  ) : (
                    <PauseSvg onClick={() => handleReason('pause')} />
                  )}

                  <SkipSvg onClick={() => handleReason('skip')} />
                </>
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() =>
              history.push(`/qsys/core/${coreId}/zone/${id}/dashboard`)
            }
            style={{
              position: 'fixed',
              bottom: 70,
              backgroundColor: '#393B5A',
              zIndex: 9999,
              height: 46,
              margin: 10,
              right: 0,
              left: 0,
              borderRadius: 3,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              alt=""
              src={zone?.currentArtwork || randomArtwork}
              style={{ height: 30, width: 30, borderRadius: 3, marginLeft: 7 }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 8,
                width: '80%',
              }}
            >
              <Marquee gradient={false} speed={35}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <h3
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {zone?.currentTitle}
                  </h3>
                  &nbsp; • &nbsp;
                  <p
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {_.capitalize(zone?.mixType)}: {zone?.mixName}
                  </p>
                </div>
              </Marquee>
              <p
                style={{
                  display: 'flex',
                  color: '#3BABA1',
                  alignItems: 'center',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                <IcoDevicesColor
                  style={{ height: 10, width: 8, marginRight: 5 }}
                />
                {zone?.zoneName}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20%',
              }}
            >
              {zone?.reason === 'pause' ? (
                <PlaySvg
                  onClick={() => handleReason('play')}
                  style={{ margin: '0px' }}
                />
              ) : (
                <PauseSvg
                  onClick={() => handleReason('pause')}
                  style={{ margin: '0px' }}
                />
              )}
            </div>
          </div>
        ))}
      {children}
    </>
  );
};

export default NowPlayingWrapper;
