/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { DELETE_QSYS_CORE } from '../mutations/delete-qsys-core';

export default function useDeleteQsysCore() {
  const [deleteQsysCore, { loading }] = useMutation(DELETE_QSYS_CORE);

  return {
    deleteQsysCore,
    loading,
  };
}
