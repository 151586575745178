/**
 * External dependencies
 */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import stc from 'string-to-color';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Page404 from 'views/404/404';
import CardLink from 'components/card-link/card-link';
import useAllPlaylists from '../../../music/hooks/use-all-playlists';
import LoadingProvider from 'components/loading-provider/loading-provider';
import * as rdd from 'react-device-detect';
import MusicListItem from '../../../music/components/music-list-item';

const SingleIntensityPage = ({
  match: {
    params: { id, intensity, coreId },
  },
}) => {
  const intensityName = intensity.charAt(0).toUpperCase() + intensity.slice(1);
  const { allPlaylists, loading: playlistsLoading } = useAllPlaylists();
  const loading = playlistsLoading;

  if (!loading & !allPlaylists) {
    return <Page404 />;
  }
  const playlists = allPlaylists?.filter((a) => a.tempo === intensityName);

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={''}
      title={intensityName}
      modifier="current-playlist"
    >
      <LoadingProvider loading={!allPlaylists}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {playlists.length} Playlists Available
            </h4>

            {playlists?.map((item) => {
              return (
                <MusicListItem
                  key={item.id}
                  to={`/qsys/core/${coreId}/zone/${id}/search/playlists/${item.id}`}
                  cover={item?.cover}
                  name={item?.name}
                  description={item?.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {playlists?.map((playlist) => (
              <CardLink
                triggerPlayNow={{
                  playlistId: playlist?.id,
                  devicePlayType: 'PLAYLIST',
                }}
                key={playlist?.id}
                to={`/qsys/core/${coreId}/zone/${id}/search/playlists/${playlist.id}`}
                cover={playlist?.cover}
                title={playlist?.name}
                color={stc(playlist?.name)}
                style={{ height: 230, margin: 0 }}
                hasButton
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleIntensityPage;
