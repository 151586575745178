import React, { useEffect, useState } from 'react';

import DashboardLayout from '../../layouts/dashboard-layout';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CardLink from 'components/card-link/card-link';
import CardLinks from 'components/card-links/card-links';
import * as rdd from 'react-device-detect';
import SearchSection from 'components/search-section/search-section';
import Field from '../../components/field/field';
import SearchSvg from '../../assets/svgComponents/SearchSvg';
import _ from 'lodash';

import useAllPlaylists from '../music/hooks/use-all-playlists';
import useAllScenes from '../music/hooks/use-all-scenes';

import LoadingProvider from '../../components/loading-provider/loading-provider';
import Page404 from 'views/404/404';
import useTriggerQsysAction from './hooks/use-trigger-qsys-action';

const QsysMusicScreen = ({ qsysDevices }) => {
  const {
    params: { coreId, id: zoneId },
  } = useRouteMatch();
  const history = useHistory();

  const [featuredPlayables, setFeaturedPlayables] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const { allFeaturedPlaylists, loading: loadingPlaylists } = useAllPlaylists();
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();

  const { triggerQsysAction } = useTriggerQsysAction();

  useEffect(() => {
    if (!loadingScenes && !loadingPlaylists) {
      const featuredConcat = [...allFeaturedScenes, ...allFeaturedPlaylists];

      setFeaturedPlayables(_.sampleSize(featuredConcat, 4));
    }
  }, [loadingScenes, loadingPlaylists]);

  const [zone, setZone] = useState(() => {
    const device = qsysDevices.find((d) => d.id === coreId);
    return device?.zones?.find((zone) => zone?.zoneId.toString() === zoneId);
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    history.push(`/qsys/core/${coreId}/zone/${zoneId}/search/${searchValue}`);
  };

  const onNavigate = (e) => {
    e.stopPropagation();
    history.push(`/qsys/core/${coreId}/zone/${zoneId}/search/all/featured`);
  };

  const handlePlayNowClick = (item) => {
    triggerQsysAction({
      variables: {
        deviceId: coreId,
        zoneId: Number(zoneId),
        actions: {
          mixId: item?.id,
          mixType: item?.type,
        },
      },
    });
  };

  if (qsysDevices && !zone) {
    return <Page404 />;
  }

  return (
    <LoadingProvider
      loading={loadingScenes || loadingPlaylists || !qsysDevices}
    >
      <DashboardLayout
        subtitle="Curator"
        title={'Music'}
        modifier="music"
        isOnMusicRoute
      >
        <div className="music-search">
          {/* MOBILE SEARCH */}
          {rdd?.isMobileOnly && (
            <form
              action="?"
              onSubmit={handleSubmit}
              className="page__head"
              style={{
                display: 'inherit',
                paddingTop: 0,
              }}
            >
              <Field className="music__button" type="submit" value="Search" />
              <Field
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="music-field"
                type="search"
                icon={<SearchSvg />}
                style={{ borderColor: '#393B5A' }}
              />
            </form>
          )}

          {/* FEATURED PLAYLISTS */}
          {!rdd?.isMobileOnly && featuredPlayables.length > 0 && (
            <SearchSection
              title={'Featured Playlists and Scenes'}
              collectionId="scenes"
              hasViewAll={true}
              to={`/qsys/core/${coreId}/zone/${zoneId}/search/all/featured`}
            >
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridGap: 20,
                  marginBottom: 20,
                }}
              >
                {featuredPlayables?.map((item) => {
                  const to =
                    item.type === 'playlist'
                      ? `/qsys/core/${coreId}/zone/${zoneId}/search/playlists/${item?.id}`
                      : `/qsys/core/${coreId}/zone/${zoneId}/search/all/scene/${item?.id}`;
                  return (
                    <CardLink
                      key={item?.id}
                      handleClicked={() => handlePlayNowClick(item)}
                      to={to}
                      title={item?.name}
                      cover={item?.cover}
                      // style={{ height: 230 }}
                      hasButton
                    />
                  );
                })}
              </div>
            </SearchSection>
          )}

          {/* MOBILE FEATURED PLAYLISTS */}
          {rdd?.isMobileOnly && featuredPlayables.length > 0 && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  padding: '0px 13px',
                }}
              >
                <h1 style={{ fontSize: 16 }}>Featured Playlists and Scenes</h1>
                <h1
                  style={{ fontSize: 16, color: '#3BABA1' }}
                  onClick={onNavigate}
                >
                  View all
                </h1>
              </div>

              <div
                style={{
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                }}
              >
                {featuredPlayables?.map((item, index) => {
                  const to =
                    item.type === 'playlist'
                      ? `/qsys/core/${coreId}/zone/${zoneId}/search/playlists/${item.id}`
                      : `/qsys/core/${coreId}/zone/${zoneId}/search/all/scene/${item.id}`;
                  return (
                    <CardLink
                      key={item?.id}
                      handleClicked={() => handlePlayNowClick(item)}
                      to={to}
                      title={item?.name}
                      cover={item?.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: 13,
                        padding: '20px 20px 16%',
                        marginRight:
                          index === featuredPlayables?.length - 1 ? 13 : 7,
                      }}
                      hasButton
                    />
                  );
                })}
              </div>
            </div>
          )}

          <h4
            style={{
              fontSize: 16,
              marginBottom: rdd?.isMobileOnly ? 7 : 20,
              marginLeft: rdd?.isMobileOnly ? 13 : 0,
              marginTop: rdd?.isMobileOnly ? 68 : 40,
            }}
          >
            Discover Playlists and Scenes
          </h4>

          <CardLinks large>
            <CardLink
              to={`/qsys/core/${coreId}/zone/${zoneId}/search/all/scenes`}
              title="Scenes"
              description="Expertly curated Scenes for every business, from restaurants, shops, and hotels, to offices, schools, and fitness centers."
              color="#C361E9"
              graphic={require('assets/images/card-image-1.png').default}
              graphicAlign="right bottom"
              customStyle={{ mixBlendMode: 'overlay' }}
            />

            <CardLink
              to={`/qsys/core/${coreId}/zone/${zoneId}/search/all/genres`}
              title="Genres"
              description="Discover new and classic tracks sorted by the genre. Rock out, get jazzy, or add a splash of Pop whenever the mood strikes."
              color="#670BCE"
              graphic={require('assets/images/card-image-2.png').default}
              graphicAlign="center bottom"
            />

            <CardLink
              to={`/qsys/core/${coreId}/zone/${zoneId}/search/all/intensities`}
              title="Intensities"
              description="Set the perfect pace ranging from easy and smooth to upbeat and eclectic."
              color="#0BCEC9"
              graphic={require('assets/images/card-image-3.png').default}
              graphicAlign="right top"
            />

            <CardLink
              to={`/qsys/core/${coreId}/zone/${zoneId}/search/all/vibe`}
              title="Vibes"
              description="Find the vibe that resonates with your crowd. Pick things and get energetic or mellow out with a wash of calm tunes."
              color="#6183E9"
              graphic={require('assets/images/card-image-4.png').default}
              graphicAlign="center bottom"
            />
          </CardLinks>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            marginTop: 90,
            paddingBottom: 50,
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Need help?</p>
          <p>
            Visit{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="https://support.activaire.com/q-sys"
            >
              https://support.activaire.com/q-sys
            </a>
          </p>
        </div>
      </DashboardLayout>
    </LoadingProvider>
  );
};

export default QsysMusicScreen;
