import SystemDetailQsys from './system-detail-qsys';

const CoreShowDetails = ({ device }) => {
  const formatDate = (dateString) => {
    const date = new Date(Number(dateString));
    return date instanceof Date && !isNaN(date)
      ? date.toLocaleString()
      : 'Unspecified';
  };

  return (
    <div className="system-details-qsys">
      <div
        className="system-details-qsys__body"
        style={{
          padding: '20px',
          borderRadius: '3px',
        }}
      >
        <SystemDetailQsys
          title="Model"
          info={device.design?.platform || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Activaire ID"
          info={device.deviceId || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Territory"
          info={device.countryCode || 'Unspecified'}
        />

        <SystemDetailQsys
          title="IP Address"
          info={device.ipAddress || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Date & Time"
          info={new Date(device.coreDate).toLocaleString() || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Uptime"
          info={`${Math.floor(device.coreUptime / 3600)}h ${Math.floor(
            (device.coreUptime % 3600) / 60
          )}m`}
        />

        <SystemDetailQsys
          title="Firmware Version"
          info={device.systemVersion || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Plugin Version"
          info={device.plugin?.buildVersion || 'Unspecified'}
        />

        <SystemDetailQsys
          title="Creation"
          info={formatDate(device.dateCreated)}
        />

        <SystemDetailQsys
          title="Latest Activity"
          info={formatDate(device.lastHeartbeat)}
        />

        <SystemDetailQsys
          title="Active Streams"
          info={`${device.zones?.length || 0}`}
        />

        <SystemDetailQsys
          title="Streams Cap"
          info={`${device.noOfZones || 0}`}
        />
      </div>
    </div>
  );
};

export default CoreShowDetails;
