/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SystemDetail from 'components/system-detail/system-detail';
import { ReactComponent as IcoIp } from 'assets/svg-icons/ico-ip.svg';
import { ReactComponent as IcoProcessor } from 'assets/svg-icons/ico-processor.svg';
import { ReactComponent as IcoLocation } from 'assets/svg-icons/ico-location.svg';
import useAllQsysDevices from '../hooks/use-all-qsys-devices';

const DeviceInfoPage = ({
  match: {
    params: { coreId },
  },
}) => {
  const { allQsysDevices } = useAllQsysDevices();
  const device = allQsysDevices.find((d) => d.id === coreId);

  const formatDate = (dateString) => {
    const date = new Date(Number(dateString));
    return date instanceof Date && !isNaN(date)
      ? date.toLocaleString()
      : 'Unspecified';
  };

  return (
    <DashboardLayout
      subtitle={''}
      title="Device Info"
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="device-info"
    >
      <div className="system-details">
        <h4>System Details</h4>

        <div className="system-details__body">
          <SystemDetail
            icon={<IcoProcessor />}
            title="Model"
            info={device?.design?.platform || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoIp />}
            title="Activaire ID"
            info={device?.deviceId || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoLocation />}
            title="Territory"
            info={device?.countryCode || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoIp />}
            title="IP Address"
            info={device?.ipAddress || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Date & Time"
            info={new Date(device?.coreDate).toLocaleString() || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Uptime"
            info={`${Math.floor(device?.coreUptime / 3600)}h ${Math.floor(
              (device?.coreUptime % 3600) / 60
            )}m`}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Firmware Version"
            info={device?.systemVersion || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Plugin Version"
            info={device?.plugin?.buildVersion || 'Unspecified'}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Creation"
            info={formatDate(device?.dateCreated)}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Latest Activity"
            info={formatDate(device?.lastHeartbeat)}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Active Streams"
            info={`${device?.zones?.length || 0}`}
          />

          <SystemDetail
            icon={<IcoProcessor />}
            title="Streams Cap"
            info={`${device?.noOfZones || 0}`}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DeviceInfoPage;
