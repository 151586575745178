/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_QSYS_DEVICES = gql`
  query allQsysDevicesQuery {
    viewer {
      allQsysDevices {
        id
        deviceId
        name
        design {
          platform
        }
        ipAddress
        systemVersion
        countryCode
        coreDate
        coreUptime
        dateCreated
        lastHeartbeat
        plugin {
          buildVersion
        }
        noOfZones
        zones {
          zoneId
          zoneName
          songCount
          songId
          currentAlbum
          currentTitle
          currentArtist
          currentArtwork
          reason
          positionMillis
          durationMillis
          updateTrigger
          songReaction
          mixType
          mixId
          mixName
          volume
        }
      }
    }
  }
`;
