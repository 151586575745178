import React, { useState } from 'react';
import * as rdd from 'react-device-detect';
import Field from '../../../components/field/field';
import SearchSvg from '../../../assets/svgComponents/SearchSvg';
import { useHistory, useParams } from 'react-router-dom';

const MusicSearchInput = ({ id, sonosDeviceId }) => {
  const history = useHistory();
  const params = useParams();
  const [searchValue, setSearchValue] = useState();
  const currentPath = history.location.pathname;
  let locationTo;

  if (currentPath.startsWith('/qsys')) {
    const { coreId = params.coreId } = params;
    locationTo = `/qsys/core/${coreId}/zone/${id}/search/${searchValue}`;
  } else if (currentPath.startsWith('/sonos')) {
    locationTo = `/sonos/${sonosDeviceId}/search/${searchValue}`;
  } else {
    locationTo = `/${id}/search/${searchValue}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    history.push(locationTo);
  };

  return (
    <div className="music-search">
      {!rdd?.isMobileOnly && (
        <form action="?" onSubmit={handleSubmit}>
          <Field className="music__button" type="submit" value="Search" />
          <Field
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            className="music-field"
            type="search"
            icon={<SearchSvg />}
            style={{ borderColor: '#393B5A' }}
          />
        </form>
      )}
    </div>
  );
};

export default MusicSearchInput;
