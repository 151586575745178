/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const DELETE_QSYS_CORE = gql`
  mutation deleteQsysCore($deviceId: ID!) {
    deleteQsysCore(deviceId: $deviceId)
  }
`;
