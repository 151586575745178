/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Playlist from 'blocks/playlist/playlist';
import PlaylistMobile from 'blocks/playlist/playlist-mobile';
import useSingleDevice from 'domain/devices/hooks/use-single-device';
import Page404 from 'views/404/404';
import useSinglePlaylist from '../hooks/use-single-playlist';
import { Howl } from 'howler';
import { SongItem } from './song-item';
import useAllAudioDevices from '../../devices/hooks/use-all-audio-devices-ids';
import { useHistory } from 'react-router-dom';
import useSonosDevice from '../../sonos/hooks/use-sonos-device';
import useAuth from '../../auth/hooks/use-auth';

const CurrentPlaylistPage = ({
  match: {
    params: { id, playlistId, sonosDeviceId },
  },
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const allDevicesQuery = useAllAudioDevices();
  const isSonosDevice = !!sonosDeviceId;
  const { device, loading } = isSonosDevice
    ? useSonosDevice(user?.id)
    : useSingleDevice(id);
  const [songSkip, songLimit] = [0, 30];
  const { playlist, loading: playlistLoading } = useSinglePlaylist(
    playlistId,
    songSkip,
    songLimit,
    device?.id
  );
  const [allSongs, setAllSongs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);
  // console.log(!allDevicesQuery?.loading, allDevicesQuery?.allDevices[0]);

  useEffect(() => {
    if (!playlistLoading) {
      setAllSongs(playlist?.allSongs || []);
    }
  }, [playlistLoading]);

  useEffect(() => {
    if (currentIndex > 0) {
      playPreview(currentIndex);
    }
  }, [currentIndex]);

  if (!device && !loading && !playlistLoading && !allDevicesQuery?.loading) {
    // this will replace the device id in the url with an existing device id accessible to the user
    if (allDevicesQuery?.allDevices?.length > 0 && !isSonosDevice) {
      history.push(
        `/${allDevicesQuery?.allDevices[0].id}/search/playlists/${playlistId}`
      );
    } else if (sonosDeviceId) {
      history.push(`/sonos/${sonosDeviceId}/search/playlists/${playlistId}`);
    } else {
      return <Page404 />;
    }
  }

  let sound = window.Howler._howls[0] || null;

  const stopSound = () => {
    sound?.stop();
    sound?.unload();
    window.Howler.unload();
    sound = null;
  };

  const playPreview = (index) => {
    const song = allSongs[index];
    if (sound !== null && song?.preview?.url !== sound._src) {
      stopSound();
    }

    if (sound === null || !sound.playing()) {
      startNewHowl(index);
    }

    if (sound !== null && sound.playing()) {
      setPlayingIndex(-1);
      stopSound();
    }
  };

  const startNewHowl = (index) => {
    stopSound();
    const song = allSongs[index];
    sound = new Howl({
      src: [song?.preview?.url || ''],
      onplay: () => {
        setPlayingIndex(index);
      },
      onend: () => {
        stopSound();
        if (index < allSongs.length - 1) {
          startNewHowl(index + 1);
        } else {
          setCurrentIndex(index + 1);
        }
      },
      html5: true,
    });
    sound?.play();
  };

  if (!playlistLoading && !playlist) {
    return <Page404 />;
  }

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={!loading && device ? device.name : null}
      title={!playlistLoading ? playlist?.name : null}
      isSinglePlaylist={rdd?.isMobileOnly && !!playlistId}
      playlistInfo={{
        deviceId: device?.id || '',
        devicePlayType: 'PLAYLIST',
        devicePlayTypeId: playlistId,
      }}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="current-playlist"
    >
      {rdd?.isMobileOnly ? (
        <PlaylistMobile
          devicePlayType="PLAYLIST"
          playlistId={playlistId}
          device={device}
          sonosDeviceId={sonosDeviceId}
          title={!playlistLoading ? playlist.description : null}
          description={
            !playlistLoading
              ? playlist?.featuredArtists?.join(' - ') || ''
              : null
          }
          color={stc(playlist?.name)}
          cover={
            !playlistLoading
              ? playlist.cover
              : require('assets/images/offline-artwork/offline3.png').default
          }
          showPreviewButton
          handlePreview={() => playPreview(0)}
        >
          {!playlistLoading &&
            allSongs?.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
        </PlaylistMobile>
      ) : (
        <Playlist
          devicePlayType="PLAYLIST"
          playlistId={playlistId}
          sonosDeviceId={sonosDeviceId}
          currentSongId={device?.currentSongId}
          title={!playlistLoading ? playlist?.description : null}
          description={
            !playlistLoading
              ? playlist?.featuredArtists?.join(' - ') || ''
              : null
          }
          color={stc(playlist?.name)}
          cover={
            !playlistLoading
              ? playlist?.cover
              : require('assets/images/offline-artwork/offline3.png').default
          }
          showPreviewButton
          handlePreview={() => playPreview(0)}
        >
          {!playlistLoading &&
            allSongs?.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
        </Playlist>
      )}
    </DashboardLayout>
  );
};

export default CurrentPlaylistPage;
