import React from 'react';

const iconBaseStyle = {
  cursor: 'pointer',
  marginLeft: 8,
  opacity: 0.7,
  transition: 'opacity 0.3s',
};

const iconProps = {
  width: '14',
  height: '14',
  viewBox: '0 0 24 24',
  fill: 'none',
  stroke: '#3BABA4',
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};

export const EditIcon = ({ onClick }) => (
  <svg
    {...iconProps}
    style={iconBaseStyle}
    onMouseEnter={(e) => (e.target.style.opacity = '1')}
    onMouseLeave={(e) => (e.target.style.opacity = '0.7')}
    onClick={onClick}
  >
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
  </svg>
);

export const CancelIcon = ({ onClick }) => (
  <svg
    {...iconProps}
    style={iconBaseStyle}
    onMouseEnter={(e) => (e.target.style.opacity = '1')}
    onMouseLeave={(e) => (e.target.style.opacity = '0.7')}
    onClick={onClick}
  >
    <path d="M18 6L6 18M6 6l12 12" />
  </svg>
);
