import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { ReactComponent as Eq } from '../../../assets/svg-icons/eq.svg';
import { ReactComponent as OnSchedule } from '../../../assets/svg-icons/onschedule.svg';
import Button from '../../../components/button/button';

const CurrentlyPlayingCard = ({ zone }) => {
  const history = useHistory();
  const { coreId } = useParams();

  const onNavigate = (e) => {
    e.stopPropagation();

    const mixType = zone?.mixType === 'scene' ? 'scene' : 'playlists';
    const to = `/qsys/core/${coreId}/zone/${zone?.zoneId}/search/${mixType}/${zone?.mixId}`;
    history.push(to);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 300,
        backgroundColor: '#E96187',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Eq />
        <h1
          style={{ fontSize: 23, marginTop: -30, cursor: 'pointer' }}
          onClick={onNavigate}
        >
          {_.capitalize(zone?.mixType)}: {zone?.mixName}
        </h1>
        <Button modifier="white2" style={{ width: 210 }} onClick={onNavigate}>
          View recently played
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <OnSchedule />
      </div>
    </div>
  );
};

export default CurrentlyPlayingCard;
