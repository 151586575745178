import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/dashboard-layout';
import InfoCard from 'components/info-card/info-card';
import Button from 'components/button/button';
import * as rdd from 'react-device-detect';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import useOpenStripeCustomerPortal from '../sonos/hooks/use-open-stripe-customer-portal';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useActivateQsysService from '../devices/hooks/use-activate-qsys-service';
import QsysStartTrialScreen from './qsys-start-trial-screen';
import useAllQsysDevices from './hooks/use-all-qsys-devices';

const QsysTrialScreen = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [loadingButton, setLoadingButton] = useState(false);
  const { openStripeCustomerPortal, stripePortal } =
    useOpenStripeCustomerPortal();
  const route = useLocation().pathname;
  const { allQsysDevices, loading: devicesLoading } = useAllQsysDevices();

  const { activateQsysService } = useActivateQsysService();

  // set trialEnded to true if user.stripe.qsysTrial value is false;
  const trialEnded = user?.stripe?.qsysTrial === false;

  // set didntStartTrial to true if user.stripe.qsysTrial value is undefined;
  const userDidntStartTrial =
    user?.stripe?.qsysTrial !== false && !user?.stripe?.qsysTrial;

  // check if qsysPermit is true
  const hasQsysPermit = user?.profile?.qsysPermit;
  
  // Check if user has zones (cores assigned)
  const hasZones = !devicesLoading && 
    allQsysDevices?.some((device) => device.zones?.length > 0) && 
    hasQsysPermit;

  // Add useEffect to handle redirect when qsysPermit changes
  useEffect(() => {
    // Only redirect to zones if user has qsysPermit AND has zones
    if (hasZones) {
      history.push('/qsys/zones');
    }
  }, [hasZones, history]);

  const handleSignUp = () => {
    if (userDidntStartTrial) {
      setLoadingButton(true);
      activateQsysService()
        .then(() => {
          toast.success('Q-SYS Service is active', {
            position: 'bottom-center',
            autoClose: 10000,
            hideProgressBar: true,
            bodyStyle: { color: '#fff' },
            style: { backgroundColor: '#670BCE' },
          });
        })
        .finally(() => {
          setLoadingButton(false);
        });
    } else {
      setLoadingButton(true);
      return openStripeCustomerPortal({
        variables: {
          route,
        },
      });
    }
  };

  useEffect(() => {
    if (
      stripePortal?.openStripeCustomerPortal?.startsWith(
        'https://billing.stripe.com/'
      )
    ) {
      window.location.href = stripePortal?.openStripeCustomerPortal;
    }
  }, [stripePortal]);

  let title = 'LET\'S GET IT ROCKIN\'';

  let description =
    'Discover unparalleled music selections from Activaire Curator and endless control options with the Q-SYS ecosystem.';

  let helperText =
    'Your zones will appear on this screen after activating the Activaire Curator Q-SYS plugin using the PIN above.';

  let helperText2 =
    'Your 7-day trial has started! Use this PIN to activate your Q-SYS plugin.';

  const helperText3 =
    'For assistance in generating your Q-SYS registration PIN, reach out to our support team at support@activaire.com';

  let buttonText = 'Sign Up';

  let backgroundImage = '#56CCF2';

  let cardImage = require('../../assets/images/qsys-pin.png').default;

  if (trialEnded && !hasQsysPermit) {
    title = 'YOUR 7 DAY TRIAL HAS ENDED';
    description = 'Sign up today and keep the music flowing.';
    helperText =
      'To activate Curator for Q-SYS, please contact support@activaire.com';
  }

  if (hasQsysPermit && trialEnded) {
    title = "LET'S GET IT ROCKIN'";
    description =
      'Discover unparalleled music selections from Activaire Curator and endless control options with the Q-SYS ecosystem.';
    helperText2 = 'Your Q-SYS plugin is active! Enjoy the music.';
  }

  if (userDidntStartTrial && !hasQsysPermit) {
    return <QsysStartTrialScreen />;
  } else if (userDidntStartTrial && hasQsysPermit) {
    title = "LET'S GET IT ROCKIN'";
    description =
      'Discover unparalleled music selections from Activaire Curator and endless control options with the Q-SYS ecosystem.';
    helperText2 = 'Your Q-SYS plugin is active! Enjoy the music.';
  }

  const showPin = hasQsysPermit || (!trialEnded && !userDidntStartTrial);
  const pinGenerated = !!user?.qsys?.pin;
  const showCallToActionButton =
    !hasQsysPermit && (trialEnded || userDidntStartTrial);
  const showReloadButton =
    hasQsysPermit || (!trialEnded && !userDidntStartTrial);

  return (
    <DashboardLayout
      subtitle="Curator"
      title={'Q-SYS'}
      modifier="devices"
      showSwitcher
    >
      <InfoCard
        reverse
        color={backgroundImage}
        image={cardImage}
        imgStyle={{
          width: '100%',
          zIndex: 9999,
          display: rdd.isMobileOnly && 'none',
        }}
        styles={{ height: 600, marginBottom: 35 }}
      >
        <h1 style={{ marginTop: 60, marginBottom: 17 }}>{title}</h1>

        <h3 style={{ fontSize: 16, color: '#090E4F', marginBottom: 20 }}>
          {description}
        </h3>
        {showPin && pinGenerated ? (
          <>
            <h2 style={{ fontSize: 24, color: '#090E4F', marginBottom: 20 }}>
              {helperText2}
            </h2>
            <h1
              style={{
                letterSpacing: 7.2,
                fontSize: 48,
                backgroundColor: '#fff',
                width: 'max-content',
                borderRadius: 20,
                padding: 20,
                textAlign: 'center',
                color: '#000',
              }}
            >
              {!user
                ? 'Loading'
                : user?.qsys?.pin.toString().slice(0, 3) +
                  '-' +
                  user?.qsys?.pin.toString().slice(3, 6)}
            </h1>
          </>
        ) : (
          <>
            <h2 style={{ fontSize: 24, color: '#090E4F', marginBottom: 20 }}>
              {helperText3}
            </h2>
          </>
        )}
        {showCallToActionButton && (
          <Button modifier="primary" onClick={() => handleSignUp()}>
            {loadingButton ? 'Loading...' : buttonText}
          </Button>
        )}
      </InfoCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 35,
        }}
      >
        <p style={{ padding: rdd.isMobileOnly ? '0px 13px' : 0 }}>
          {helperText}
        </p>
        {showReloadButton && (
          <Button modifier="primary" onClick={() => location.reload()}>
            Reload
          </Button>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Need help?</p>
          <p>
            Visit{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="https://support.activaire.com/q-sys"
            >
              https://support.activaire.com/q-sys
            </a>
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default QsysTrialScreen;
