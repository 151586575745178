/**
 * External dependencies
 */
import React, { useState } from 'react';
import stc from 'string-to-color';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import SearchSection from 'components/search-section/search-section';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useSearchPlaylists from '../../music/hooks/use-search-playlists';
import capitalize from '../../../helpers/capitalize';
import useAuth from '../../auth/hooks/use-auth';
import useTriggerQsysAction from '../hooks/use-trigger-qsys-action';

const MusicSearchPage = ({ id, searchTerm }) => {
  const { user, currentDevice } = useAuth();
  const { triggerQsysAction } = useTriggerQsysAction();

  const { searchedPlaylists, searchedScenes, loading } = useSearchPlaylists({
    text: searchTerm,
    currentDevice: user?.defaultDeviceId,
    currentClient: user?.profile?.clientId,
  });

  const [sections, setAllSections] = useState([
    {
      name: 'Scenes',
      expanded: false,
    },
    {
      name: 'Playlists',
      expanded: false,
    },
  ]);

  const handleViewAll = (section) => {
    let tempSections = [...sections];
    tempSections[section] = {
      ...tempSections[section],
      expanded: true,
    };
    setAllSections(tempSections);
  };

  const handlePlayNowClick = (item, type) => {
    triggerQsysAction({
      variables: {
        deviceId: currentDevice,
        zoneId: Number(id),
        actions: {
          mixId: item.id,
          mixType: type,
        },
      },
    });
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={`Search "${searchTerm}"`}
      modifier="music-search"
    >
      <LoadingProvider loading={loading}>
        {!searchedPlaylists?.length && !searchedScenes?.length && (
          <h2>Nothing found. Try something else</h2>
        )}
        {searchedPlaylists?.length > 0 && (
          <SearchSection
            title="Playlists"
            deviceId={id}
            collectionId="playlists"
            hasViewAll
            isInSearchSection={
              !sections[1].expanded && searchedPlaylists?.length > 8
            }
            onClick={() => handleViewAll(1)}
          >
            <div className="section-search-result__items">
              {!loading &&
                searchedPlaylists &&
                searchedPlaylists
                  .slice(0, sections[1].expanded ? searchedPlaylists.length : 8)
                  .map((playlist) => (
                    <CardLink
                      key={playlist.id}
                      to={`/qsys/core/${currentDevice}/zone/${id}/search/playlists/${playlist.id}`}
                      cover={playlist.cover}
                      title={playlist.name}
                      color={stc(playlist.name)}
                      // style={{ height: 230 }}
                      hasButton
                      handleClicked={() =>
                        handlePlayNowClick(playlist, 'playlist')
                      }
                    />
                  ))}
            </div>
          </SearchSection>
        )}

        {searchedScenes?.length > 0 && (
          <SearchSection
            title="Scenes"
            deviceId={id}
            collectionId="scenes"
            hasViewAll
            isInSearchSection={
              !sections[0].expanded && searchedScenes?.length > 8
            }
            onClick={() => handleViewAll(0)}
          >
            <div className="section-search-result__items">
              {!loading &&
                searchedScenes
                  ?.slice(0, sections[0]?.expanded ? searchedScenes?.length : 8)
                  .map((scene) => (
                    <CardLink
                      key={scene?.id}
                      to={`/qsys/core/${currentDevice}/zone/${id}/search/all/scene/${scene?.id}`}
                      title={capitalize(scene?.name?.toLowerCase())}
                      cover={scene?.cover}
                      hasButton
                      handleClicked={() => handlePlayNowClick(scene, 'scene')}
                    />
                  ))}
            </div>
          </SearchSection>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default MusicSearchPage;
