/**
 * External dependencies
 */
import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import { format } from 'date-fns';
import moment from 'moment';

/**
 * Internal dependencies
 */
import ScheduleBlock from 'components/schedule-block/schedule-block';
import parseDate from 'helpers/parseDate';
import getArrayFromDates from 'helpers/get-array-from-dates';

import getRandomColor from 'helpers/get-random-color';
import getHours from 'helpers/get-hours';
import _ from 'lodash';

const isCurrent = (startTime, endTime) => {
  const currentMinuteOfTheDay = moment().hour() * 60 + moment().minutes();
  return startTime <= currentMinuteOfTheDay && endTime >= currentMinuteOfTheDay;
};

const Schedule = ({ scheduleData, device }) => {
  const currentSchedule = useMemo(() => {
    const localScheduleData = scheduleData?.map((schedule) => {
      schedule.playingPlaylists = [...schedule.playingPlaylists].map((item) =>
        Object.assign({ color: getRandomColor(_.random(14)) }, item)
      );

      const todayDate = new Date();

      const daysArray = getArrayFromDates(
        todayDate,
        parseDate(schedule.endDate)
      )
        .slice(0, 20)
        .map((date) => {
          const formattedDate = format(date, 'EEEE do MMMM');

          return {
            day: date.getDay(),
            date,
            formattedDate,
            playlists: schedule.playingPlaylists
              .filter((playlist) => {
                const dayOfTheWeek = date.getDay() > 0 ? date.getDay() - 1 : 6;
                return playlist?.days[dayOfTheWeek];
              })
              .map((playlist) => {
                return {
                  ...playlist,
                  timeSpan: getHours(playlist.startTime, playlist.endTime),
                };
              }),
          };
        });

      return {
        ...schedule,
        remainingDays: daysArray,
      };
    });
    const schedule = localScheduleData?.find(
      (item) => device?.scheduleIds?.length > 0 && item.id === device?.scheduleIds[0]
    );

    return {
      ...schedule,
      // remainingDays: schedule?.remainingDays.filter((scheduleDay) => {
      //   return scheduleDay.playlists.some(
      //     (playlist) => playlist.days[scheduleDay.day]
      //   );
      // }),
    };
  }, [scheduleData, device?.scheduleIds]);

  return (
    <Swiper
      className="schedule"
      spaceBetween={16}
      slidesPerView={2.4}
      style={{
        marginTop: device?.isOnline ? 68 : 0,
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      {currentSchedule?.remainingDays?.map((scheduleDay, index) => (
        <SwiperSlide key={scheduleDay.date}>
          <div className="schedule__day">
            <h5>{scheduleDay.formattedDate}</h5>

            <div className="schedule__day-blocks">
              {scheduleDay.playlists
                .sort((a, b) => {
                  if (a?.startTimeSeconds < b?.startTimeSeconds) {
                    return -1;
                  } else if (a?.startTimeSeconds > b?.startTimeSeconds) {
                    return 1;
                  }
                  return 0;
                })
                .map((playlist) => {
                  let currentPlaylist = false;
                  if (index === 0) {
                    currentPlaylist = isCurrent(
                      playlist?.startTime,
                      playlist?.endTime
                    );
                  }
                  return (
                    <ScheduleBlock
                      isCurrent={currentPlaylist}
                      key={
                        playlist?.startTime + playlist?.endTime + playlist?.name
                      }
                      timeSpan={playlist.timeSpan}
                      title={playlist?.name}
                      color={playlist?.color}
                      description={playlist?.description}
                    />
                  );
                })}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Schedule;
