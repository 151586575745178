/**
 * External dependencies
 */
import React, { useEffect, useState, useContext } from 'react';
import cx from 'classnames';

/**
 * Internal dependencies
 */
import DashboardLayout from '../../layouts/dashboard-layout';
import Player from 'components/player/player';
import getRandomArtwork from '../../helpers/get-random-artwork';
import { Link, useLocation } from 'react-router-dom';

import ModalPaymentStatus from '../../components/modal-status/modal-payment-status';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import useSonosDevice from './hooks/use-sonos-device';
import SonosErrorPage from './sonos-error-page';

import useSonosDeviceSubscription from 'domain/sonos/hooks/use-sonos-device-subscription';
import Button from '../../components/button/button';
import useCreateSonosCloudToken from './hooks/use-create-sonos-cloud-token';
import VolumeSvg from '../../assets/svgComponents/VolumeSvg';
import ProgressRangeFooter from '../../components/volume-slider/volume-slider-footer-sonos';
import PlaySvg from '../../assets/svgComponents/PlaySvg';
import PauseSvg from '../../assets/svgComponents/PauseSvg';
import SkipSvg from '../../assets/svgComponents/SkipSvg';
import * as rdd from 'react-device-detect';
import useSonosDeviceAction from './hooks/use-sonos-device-action';
import SearchSection from '../../components/search-section/search-section';
import CurrentlyPlayingCardSonos from '../../components/current-playlist-card/currently-playing-card-sonos';
import { notify } from '../../utils/notify-toast';
import isSonosAccessTokenAvailable from '../../utils/check-sonos-cookie';

function useQuery() {
  const URLSearchParams = window.URLSearchParams;
  return new URLSearchParams(useLocation().search);
}

const SonosDashboard = () => {
  let query = useQuery();
  const { user, accessTokenValid, setAccessTokenValid } =
    useContext(AuthContext);
  const [device, setDevice] = useState({});
  const [sonosState, setSonosState] = useState(query.get('state'));
  const [code, setCode] = useState(query.get('code'));
  const [disablePlayPause, setDisablePlayPause] = useState(false);
  const { device: sonosDevice, loading, refetch } = useSonosDevice(user?.id);
  const [randomArtwork, setRandomArtwork] = useState();
  const { sonosDevicesSubscription, sonosDevicesSubscriptionloading } =
    useSonosDeviceSubscription();

  const { createSonosCloudToken } = useCreateSonosCloudToken();
  const { sonosDeviceAction } = useSonosDeviceAction();

  useEffect(() => {
    if (!sonosDevicesSubscriptionloading) {
      const updatedSonosDevice =
        sonosDevicesSubscription?.SonosDeviceSubscription?.node;
      setDevice(updatedSonosDevice);
    }
  }, [sonosDevicesSubscription]);

  useEffect(() => {
    if (!loading) {
      setDevice(sonosDevice);
    }
  }, [sonosDevice]);

  useEffect(() => {
    if (!sonosDevicesSubscriptionloading) {
      setDisablePlayPause(false);
    }
  }, [device?.reason]);

  useEffect(() => {
    setRandomArtwork(getRandomArtwork());
    console.log(isSonosAccessTokenAvailable());
    refetch();
  }, []);

  // if sonosState and code are not null, then make a request to the server to get the access token
  useEffect(() => {
    if (sonosState && code) {
      // check if the sonosState is the same as the one saved in the local storage
      if (sonosState === localStorage.getItem('sonosState')) {
        // make a request to the server to get the access token
        createSonosCloudToken({
          variables: {
            code,
          },
        })
          .then((response) => {
            if (response?.data?.createSonosCloudToken === 'active') {
              // if the request is successful, save the access token in the local storage as cookie with 23 hours and 59 minutes expiration time
              document.cookie = `sonosAccessToken=${
                response?.data?.createSonosCloudToken
              }, ${new Date(
                new Date().getTime() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000
              )}; path=/;`;
              setAccessTokenValid(isSonosAccessTokenAvailable());
            } else if (
              response?.data?.createSonosCloudToken ===
              'activaire_service_not_connected_to_sonos_account'
            ) {
              notify(
                'Use the Sonos app to connect your device to your account, add the Activaire Service and then try again.'
              );
              // Remove the sonosAccessToken cookie
              document.cookie =
                'sonosAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
        // if the request is not successful, show an error message
        // clear the sonosState from the local storage
        localStorage.removeItem('sonosState');
      }
    }
  }, [sonosState, code]);

  const handlePlayPause = () => {
    setDisablePlayPause(true);
    // Call Sonos Cloud API to play or pause the music
    sonosDeviceAction({
      variables: {
        deviceId: device?.id,
        action: 'togglePlayPause',
      },
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const handleNextClick = () => {
    // Call Sonos Cloud API to play the next song
    sonosDeviceAction({
      variables: {
        deviceId: device?.id,
        action: 'skipToNextTrack',
      },
    })
      .then((res) => {
        if (res?.data?.sonosDeviceAction === 'skip_limit_reached') {
          notify('Song skip limit reached');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleConnectYourDevice = () => {
    // generate a random uuid
    const uuid = crypto.randomUUID();
    // save the uuid in the local storage
    localStorage.setItem('sonosState', uuid);
    return (location.href = `https://api.sonos.com/login/v3/oauth?client_id=${process.env.REACT_APP_SONOS_CLIENT_KEY}&response_type=code&state=${uuid}&scope=playback-control-all&redirect_uri=${process.env.REACT_APP_SONOS_REDIRECT_URL}`);
  };

  console.log(user?.sonos);

  return (
    <DashboardLayout
      subtitle=""
      title={
        !user?.profile?.sonosPermit
          ? 'My Sonos Experience (Offline)'
          : 'My Sonos Experience'
      }
      modifier={cx('dashboard', {
        'page--offline': !user?.profile?.sonosPermit,
      })}
      showSwitcher={
        user?.serviceType?.includes('SONOS') && user?.serviceType?.length > 0
      }
      sonosDeviceIdProp={device?.id}
    >
      {/* removed loading props from player to avoid rerender visual  */}
      {user?.profile?.sonosPermit ? (
        <Player
          //  clientId={id}
          loading={loading}
          title={device?.currentTitle || null}
          author={device?.currentArtist || null}
          album={device?.currentAlbum || null}
          cover={device?.currentArtwork || null}
          //  lastOnline={device?.lastOnline || null}
          reason={device?.reason}
          isPlaying={true}
          isOnline={true}
          hideVolumeBar={true}
        />
      ) : (
        <SonosErrorPage randomArtwork={randomArtwork} />
      )}
      {(user?.profile?.sonosPermit &&
      sonosDevice &&
      !accessTokenValid) ||
      user?.sonos?.cloudTokenAuthNeeded ? (
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <Button
            modifier="primary"
            onClick={handleConnectYourDevice}
            style={{ fontSize: 16 }}
          >
            Connect your device
          </Button>
        </div>
      ) : (
        <>
          <div
            style={{
              height: 80,
              maxWidth: 980,
              backgroundColor: '#000',
              borderRadius: 13,
              marginLeft: rdd?.isMobileOnly ? 13 : 0,
              marginRight: rdd?.isMobileOnly ? 13 : 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!user?.sonos?.hideVolumeBar && <VolumeSvg />}
            {!user?.sonos?.hideVolumeBar && (
              <ProgressRangeFooter
                deviceId={device?.id}
                volume={device?.volume || 0}
              />
            )}
            {!['play', 'skip'].includes(device?.reason) ? (
              <PlaySvg
                onClick={handlePlayPause}
                disabled={disablePlayPause}
                style={{ marginLeft: 12 }}
              />
            ) : (
              <PauseSvg
                onClick={handlePlayPause}
                disabled={disablePlayPause}
                style={{ marginLeft: 12 }}
              />
            )}
            <SkipSvg onClick={handleNextClick} />
          </div>
          <SearchSection>
            <CurrentlyPlayingCardSonos device={device} />
          </SearchSection>
        </>
      )}

      <p style={{ textAlign: 'center', marginBottom: 100 }}>
        For an installation guide and resources{' '}
        <Link style={{ textDecorationLine: 'underline' }} to="/sonos/install">
          click here
        </Link>
        .
      </p>
      <ModalPaymentStatus
        color={user?.profile?.sonosPermit ? '#030416' : '#670BCE'}
        disable={true}
      />
    </DashboardLayout>
  );
};

export default SonosDashboard;
