import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/modal';
import Button from 'components/button/button';
import FieldValidated from 'components/field-validated/field-validated';
import { toast } from 'react-toastify';

import { FormProvider, useForm } from 'react-hook-form';
import useUpdateQsysZones from './hooks/use-update-qsys-zones';

const QsysModal = ({ on, toggle, zones: initialZones, deviceId }) => {
  const [zones, setZones] = useState(initialZones);
  const { updateQsysZones } = useUpdateQsysZones();
  const methods = useForm({
    mode: 'all',
    defaultValues: zones.reduce((acc, zone) => {
      acc[zone?.zoneId] = zone?.zoneName;
      return acc;
    }, {}),
  });

  useEffect(() => {
    // Reset to initial values when the modal is closed
    if (!on) {
      setZones(initialZones);
    }
  }, [on, initialZones]);

  const handleSubmit = (data) => {
    // Transform the data and ensure all zoneIds are positive
    const transformedData = zones.map((zone) => {
      const zoneId = Math.abs(zone.zoneId); // Convert any negative IDs to positive
      const zoneName = data[zone.zoneId.toString()];
      return { zoneId, zoneName };
    });

    console.log('Updating Q-SYS zones with data:', {
      deviceId,
      transformedData,
    });

    updateQsysZones({
      variables: {
        deviceId,
        zones: transformedData,
      },
    })
      .then((res) => {
        console.log('Q-SYS zones update response:', res);
        
        if (!res?.data?.updateQsysZones) {
          console.error('Q-SYS zones update failed:', {
            response: res,
            responseData: res?.data,
            error: res?.errors,
          });
          throw new Error('Failed to update streams - no response data');
        }

        const updatedZones = res.data.updateQsysZones.zones || [];
        console.log('Successfully updated Q-SYS zones:', updatedZones);
        setZones(updatedZones);

        // Reset form with new values
        const formData = updatedZones.reduce((acc, zone) => {
          if (zone?.zoneId != null) {
            acc[zone.zoneId] = zone.zoneName || '';
          }
          return acc;
        }, {});

        methods.reset(formData);
        toggle();

        toast.dark('Stream configuration updated successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          bodyStyle: { color: '#fff' },
          style: { backgroundColor: '#670BCE' },
        });
      })
      .catch((err) => {
        console.error('Error updating streams:', err);

        // More specific error messages
        let errorMessage = 'Failed to update streams';
        if (err.message.includes('Too many streams')) {
          errorMessage =
            'Maximum number of streams reached. Please contact support@activaire.com';
        }

        toast.error(errorMessage, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          bodyStyle: { color: '#fff' },
          style: { backgroundColor: '#FF4000' },
        });
      });
  };

  const addZone = () => {
    const maxZoneId = Math.max(...zones.map((z) => Math.abs(z.zoneId)), 0);
    const newZoneId = maxZoneId + 1;
    const newZone = { zoneId: newZoneId, zoneName: '' };
    setZones([...zones, newZone]);
  };
  const removeZone = (zoneId) => {
    setZones(zones.filter((zone) => zone?.zoneId !== zoneId));
  };

  return (
    <Modal
      subtitle="Curator for Q-SYS"
      title="Stream management"
      visible={on}
      onClose={toggle}
    >
      <p>
        Activaire Curator for Q-SYS provides a default of 2 streams per QSC
        Core. The number of authorized streams can be modified from this panel.
        You can enable up to 16 streams, but if you need to manage additional
        streams, please contact us at support@activaire.com
      </p>

      <p>Add, remove or rename your streams here:</p>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(handleSubmit)}
          style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        >
          {zones.map((zone) => (
            <div key={zone?.zoneId} style={{ position: 'relative' }}>
              <FieldValidated
                type="text"
                name={zone?.zoneId}
                placeholder="Stream Name"
                validations={{
                  required: 'Field is required.',
                }}
              />
              <Button
                onClick={() => removeZone(zone?.zoneId)}
                modifier="as-link"
                type="button"
                style={{
                  fontSize: 16,
                  position: 'absolute',
                  bottom: '50%',
                  transform: 'translateY(50%)',
                  right: 20,
                }}
              >
                remove
              </Button>
            </div>
          ))}
          {zones.length <= 16 && (
            <Button
              onClick={addZone}
              modifier="as-link"
              type="button"
              style={{
                fontSize: 16,
                textAlign: 'center',
                marginBottom: 50,
              }}
            >
              + Add stream
            </Button>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button modifier="primary" type="submit">
              Save
            </Button>
            <Button
              modifier="as-link"
              onClick={toggle}
              style={{ padding: '0px 30px' }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>

      <p style={{ marginTop: 50 }}>
        <b>Note</b>: Each active stream will be billed; please check with your
        account representative for additional information.
      </p>
    </Modal>
  );
};

export default QsysModal;
