/**
 * External dependencies
 */
import React from 'react';

const SystemDetailQsys = ({ icon, title, info }) => {
  return (
    <div className="system-detail-qsys">
      <div className="system-detail-qsys__content">
        <h4>{title}</h4>

        <h6 style={{ whiteSpace: 'nowrap' }}>{info}</h6>
      </div>
    </div>
  );
};

export default SystemDetailQsys;
