/**
 * External dependencies
 */
import React from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../../../music/hooks/use-all-scenes';
import MusicListItem from '../../../music/components/music-list-item';
import _ from 'lodash';
import useTriggerQsysAction from '../../hooks/use-trigger-qsys-action';

const SingleVibePage = ({
  match: {
    params: { id, vibe, coreId },
  },
}) => {
  const { allScenes } = useAllScenes();
  const { triggerQsysAction } = useTriggerQsysAction();

  const allMixes = allScenes?.filter((item) => {
    const genreUpperCase = item.vibe?.map((item) => item.toUpperCase());
    return genreUpperCase.includes(vibe);
  });

  const handlePlayNowClick = (item, type) => {
    triggerQsysAction({
      variables: {
        deviceId: coreId,
        zoneId: Number(id),
        actions: {
          mixId: item.id,
          mixType: type,
        },
      },
    });
  };

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle={''}
      title={_.capitalize(vibe)}
      modifier="current-vibe"
    >
      <LoadingProvider loading={!allMixes}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {allMixes.length} Scenes Available
            </h4>

            {allMixes?.map((item) => {
              return (
                <MusicListItem
                  key={item.id}
                  to={`/qsys/core/${coreId}/zone/${id}/search/all/scene/${item.id}`}
                  cover={item?.cover}
                  name={item?.name}
                  description={item?.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {allMixes?.map((scene) => (
              <CardLink
                key={scene?.id}
                to={`/qsys/core/${coreId}/zone/${id}/search/all/scene/${scene.id}`}
                cover={scene?.cover}
                title={scene?.name}
                color={stc(scene?.name)}
                style={{ margin: 0 }}
                hasButton
                handleClicked={() => handlePlayNowClick(scene, 'scene')}
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleVibePage;
