import React, { useContext } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../modal/modal';
import { ReactComponent as IcoBack } from '../../assets/svg-icons/ico-back-black.svg';
import Button from '../button/button';
import useAllAudioDevicesIds from '../../domain/devices/hooks/use-all-audio-devices-ids';
import LoadingProvider from '../loading-provider/loading-provider';
import { DeviceButton } from './device-button';
import useUpdateDevicesPlaylist from '../../domain/dashboard/hooks/use-update-devices-playlist';
import { Toggle } from 'react-powerplug';
import useLocalStorage from '../../utils/use-local-storage';
import AuthContext from '../../domain/auth/contexts/auth-context/auth-context';
import usePlaySonosMix from '../../domain/dashboard/hooks/use-play-sonos-mix';
import useTriggerQsysAction from '../../domain/qsys/hooks/use-trigger-qsys-action';

const PlayNowModal = ({ children, devicePlayType, playlistId }) => {
  const { currentDevice, user } = useContext(AuthContext);
  const history = useHistory();
  const { coreId, id } = useParams();

  const { triggerQsysAction } = useTriggerQsysAction();
  const { playSonosMix } = usePlaySonosMix();

  const isSonosDevice = history.location.pathname.startsWith('/sonos');
  const isQsysDevice = history.location.pathname.startsWith('/qsys');
  const {
    allDevices = [],
    loading,
    count,
  } = useAllAudioDevicesIds({
    fetchPolicy: 'network-and-cache',
  });
  const { updateDevicesPlaylist } = useUpdateDevicesPlaylist();
  const [selectedDevices, setSelectedDevices] = useLocalStorage(
    'selectedDevices',
    []
  );

  const handleSelect = (deviceId, selected) => {
    if (selected) {
      const index = selectedDevices.indexOf(deviceId);
      const newSelectedDevices = [...selectedDevices];
      newSelectedDevices.splice(index, 1);
      setSelectedDevices(newSelectedDevices);
    } else {
      const newSelectedDevices = [...selectedDevices];
      newSelectedDevices.push(deviceId);
      setSelectedDevices(newSelectedDevices);
    }
  };

  const handleSelectAll = () => {
    const allSelected = _.isEmpty(
      _.xor(
        selectedDevices,
        allDevices.map((item) => item.id)
      )
    );
    if (allSelected) {
      setSelectedDevices([]);
    } else {
      setSelectedDevices(allDevices.map((item) => item.id));
    }
  };

  const handlePlayNow = async (currentDeviceSelected) => {
    if (isQsysDevice) {
      triggerQsysAction({
        variables: {
          deviceId: coreId,
          zoneId: Number(id),
          actions: {
            mixId: playlistId,
            mixType: devicePlayType.toLowerCase(),
          },
        },
      });
    } else if (
      selectedDevices.length > 0 ||
      currentDeviceSelected ||
      isSonosDevice
    ) {
      isSonosDevice
        ? await playSonosMix({
            variables: {
              deviceId: currentDevice,
              mixType: devicePlayType,
              mixId: playlistId,
            },
          }).then(() => {
            history.push(`/sonos/dashboard`);
          })
        : await updateDevicesPlaylist({
            variables: {
              deviceIds: currentDeviceSelected || selectedDevices,
              devicePlayType: devicePlayType,
              devicePlayTypeId: playlistId,
            },
          }).then(() => {
            history.push(`/${currentDevice}`);
          });
    }
  };

  return (
    <>
      <Toggle
        initial={false}
        render={({ on, toggle }) => (
          <>
            {React.cloneElement(children, {
              onClick: (event) => {
                if (user?.rolesConsole?.roleAccess >= 5 || isSonosDevice) {
                  handlePlayNow([currentDevice]);
                } else if (isQsysDevice) {
                  handlePlayNow(coreId);
                } else {
                  if (allDevices.length === 1) {
                    handlePlayNow([currentDevice]);
                  } else {
                    const addCurrentDevice = [
                      ...(JSON.parse(localStorage.getItem('selectedDevices')) ||
                        []),
                    ];
                    addCurrentDevice.push(currentDevice);
                    setSelectedDevices([...new Set(addCurrentDevice)]);
                    toggle();
                  }
                }
              },
            })}
            <Modal visible={on} onClose={toggle} onPlayCloseButton>
              <div
                className="modal-window__container"
                style={{
                  background: '#ffffff',
                }}
              >
                <Button
                  type="button"
                  onClick={toggle}
                  style={{ margin: '27px 17px' }}
                >
                  <IcoBack />
                </Button>
                <LoadingProvider loading={loading}>
                  <div className="play-now-list">
                    <p
                      className="modal-window__title-play-now"
                      style={{ marginBottom: 0 }}
                    >
                      Play now
                    </p>
                    <p
                      className="modal-window__subtitle-play-now"
                      style={{ color: '#030416', textAlign: 'left' }}
                    >
                      On which devices?
                    </p>
                    <p
                      className="modal-window__device-count-play-now"
                      style={{ marginBottom: 0 }}
                    >
                      {count} devices. Select one or more
                    </p>
                    <DeviceButton
                      onClick={() => handleSelectAll()}
                      text="All locations..."
                      selected={_.isEmpty(
                        _.xor(
                          selectedDevices,
                          allDevices.map((item) => item.id)
                        )
                      )}
                    />
                    {allDevices.map((item) => {
                      const selected = selectedDevices.includes(item.id);
                      return (
                        <DeviceButton
                          key={item.id}
                          onClick={() => handleSelect(item.id, selected)}
                          text={item?.name || ''}
                          selected={selected}
                        />
                      );
                    })}
                  </div>
                  <div style={{ textAlign: 'center', padding: 13 }}>
                    <Button
                      modifier="primary"
                      onClick={() => handlePlayNow() && toggle()}
                      style={{
                        padding: '15px 30px',
                        ...(selectedDevices.length === 0 && {
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }),
                      }}
                    >
                      Play now
                    </Button>
                  </div>
                </LoadingProvider>
              </div>
            </Modal>
          </>
        )}
      />
    </>
  );
};

export default PlayNowModal;
