import React, { useEffect, useState } from 'react';
import Button from 'components/button/button';
import DashboardLayout from '../../layouts/dashboard-layout';
import { Toggle } from 'react-powerplug';
import getRandomColor from 'helpers/get-random-color';
import useAuth from 'domain/auth/hooks/use-auth';
import useQsysDeviceSubscription from './hooks/use-qsys-device-subscription';
import { gql, useMutation, useQuery } from '@apollo/client';
import QsysModal from './qsys-modal';
import { ReactComponent as IcoDown } from 'assets/svg-icons/ico-down.svg';
import { ReactComponent as IcoUp } from 'assets/svg-icons/ico-up.svg';
import CoreShowDetails from './components/core-show-details';
import { EditIcon } from './components/icons';
import useDeleteQsysCore from './hooks/use-delete-qsys-core';
import DeleteConfirmationModal from './components/delete-confirmation-modal';
import { toast } from 'react-toastify';
import { ALL_QSYS_DEVICES } from './query/all-qsys-devices';

const UPDATE_QSYS_CORE_NAME = gql`
  mutation updateQsysCoreName($deviceId: ID!, $name: String!) {
    updateQsysCoreName(deviceId: $deviceId, name: $name) {
      id
      name
    }
  }
`;

const EditableCoreName = ({
  device,
  deviceIndex,
  isEditing,
  editingCoreName,
  onEditStart,
  onEditChange,
  onEditCancel,
  onEditSave,
  onKeyDown,
  canEdit,
  canDelete,
  onDelete,
}) => {
  if (isEditing) {
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '12px',
        maxWidth: '100%',
        marginBottom: '24px'
      }}>
        <input
          type="text"
          value={editingCoreName}
          onChange={(e) => onEditChange(e.target.value)}
          onKeyDown={onKeyDown}
          onBlur={(e) => {
            if (!e.relatedTarget?.classList.contains('edit-action-button')) {
              onEditSave();
            }
          }}
          autoFocus
          style={{
            fontSize: 20,
            padding: '8px 12px',
            borderRadius: 13,
            border: '1.5px solid #3BABA4',
            background: 'transparent',
            color: 'inherit',
            width: '100%',
            maxWidth: '300px',
            transition: 'border-color 0.3s',
            height: '42px',
            boxSizing: 'border-box',
          }}
        />
        <div style={{ 
          display: 'flex', 
          gap: '12px', 
          alignItems: 'center', 
          height: '42px',
          justifyContent: 'flex-start'
        }}>
          <button
            className="edit-action-button"
            onClick={onEditSave}
            style={{
              background: 'transparent',
              border: '1.5px solid #3BABA4',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              width: '42px',
              height: '42px',
              borderRadius: '13px',
              transition: 'all 0.2s ease',
            }}
          >
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#3BABA4"/>
            </svg>
          </button>
          <button
            className="edit-action-button"
            onClick={onEditCancel}
            style={{
              background: 'transparent',
              border: '1.5px solid #ff4d4d',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              width: '42px',
              height: '42px',
              borderRadius: '13px',
              transition: 'all 0.2s ease',
            }}
          >
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" fill="#ff4d4d"/>
            </svg>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      {device.name || `Core ${deviceIndex + 1}`}
      {canEdit && (
        <EditIcon onClick={() => onEditStart(device.id, device.name)} />
      )}
      {canDelete && (
        <button
          onClick={() => onDelete(device.id)}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#ff4d4d',
            cursor: 'pointer',
            padding: '4px 24px 4px 12px',
            borderRadius: '4px',
            fontSize: '14px',
          }}
        >
          Delete
        </button>
      )}
    </div>
  );
};

const QsysZonesScreen = ({ qsysDevices }) => {
  const { user } = useAuth();
  const { qsysDevicesSubscription, qsysDevicesSubscriptionLoading } =
    useQsysDeviceSubscription();
  const [updateQsysCoreName] = useMutation(UPDATE_QSYS_CORE_NAME);
  const { deleteQsysCore } = useDeleteQsysCore();
  const { refetch: refetchQsysDevices } = useQuery(ALL_QSYS_DEVICES);
  const [editingCoreId, setEditingCoreId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [coreToDelete, setCoreToDelete] = useState(null);
  const [editingCoreName, setEditingCoreName] = useState('');
  const [originalCoreName, setOriginalCoreName] = useState('');

  const canEditCoreName = [1, 2, 3].includes(user?.rolesConsole?.roleAccess);
  const canDeleteCore = [1, 2, 3].includes(user?.rolesConsole?.roleAccess);

  useEffect(() => {
    if (!qsysDevicesSubscriptionLoading) {
      const updatedDevice =
        qsysDevicesSubscription?.QsysDeviceSubscription?.node;
      const updatedDevices = [...qsysDevices];
      const deviceIndex = updatedDevices.findIndex(
        (d) => d.id === updatedDevice?.id
      );
      if (deviceIndex !== -1) {
        updatedDevices[deviceIndex] = {
          ...updatedDevices[deviceIndex],
          zones: updatedDevice.zones || [],
        };
      }
    }
  }, [qsysDevicesSubscription]);

  const handleEditCoreName = (deviceId, currentName) => {
    const name =
      currentName ||
      `Core ${qsysDevices.findIndex((d) => d.id === deviceId) + 1}`;
    setEditingCoreId(deviceId);
    setEditingCoreName(name);
    setOriginalCoreName(name);
  };

  const handleCancelEdit = () => {
    setEditingCoreId(null);
    setEditingCoreName('');
    setOriginalCoreName('');
  };

  const handleSaveCoreName = async (deviceId) => {
    if (
      !editingCoreName.trim() ||
      editingCoreName.trim() === originalCoreName
    ) {
      handleCancelEdit();
      return;
    }

    try {
      await updateQsysCoreName({
        variables: {
          deviceId,
          name: editingCoreName.trim(),
        },
      });
      handleCancelEdit();
    } catch (error) {
      console.error('Error updating core name:', error);
    }
  };

  const handleDeleteCore = async (deviceId) => {
    const device = qsysDevices.find((d) => d.id === deviceId);
    setCoreToDelete(device);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const result = await deleteQsysCore({
        variables: {
          deviceId: coreToDelete.id,
        },
      });

      if (result?.data?.deleteQsysCore !== true) {
        throw new Error('Failed to delete core');
      }

      setDeleteModalOpen(false);
      setCoreToDelete(null);

      await refetchQsysDevices();

      toast.dark('Core deleted successfully', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      });
    } catch (error) {
      console.error('Error deleting core:', error);

      toast.error('Failed to delete core', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#FF4000' },
      });
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setCoreToDelete(null);
  };

  const handleKeyPress = (e, deviceId) => {
    if (e.key === 'Enter') {
      handleSaveCoreName(deviceId);
    } else if (e.key === 'Escape') {
      handleCancelEdit();
    }
  };

  return (
    <DashboardLayout
      subtitle="Curator"
      title={'Q-SYS Music streams'}
      modifier="devices"
      showSwitcher
    >
      <div
        style={{
          height: 80,
          backgroundColor: '#393B5A',
          borderRadius: 3,
          padding: 20,
          gap: 5,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 30,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>
          PIN:{' '}
          {!user
            ? 'Loading'
            : user?.qsys?.pin
            ? user?.qsys?.pin?.toString().slice(0, 3) +
              '-' +
              user?.qsys?.pin?.toString().slice(3, 6)
            : 'PIN unavailable'}
        </p>
        <p>Use this to activate your Q-SYS plugin.</p>
      </div>

      {qsysDevices.map((device, deviceIndex) => (
        <Toggle key={device.id}>
          {({ on: onDropDown, toggle: toggleDropDown }) => (
            <div className="core-container" style={{ marginBottom: 70 }}>
              <div
                className="core-header"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: 20,
                }}
              >
                <div>
                  <h3 style={{ fontSize: 20, marginBottom: 5, marginLeft: 4 }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <EditableCoreName
                        device={device}
                        deviceIndex={deviceIndex}
                        isEditing={editingCoreId === device.id}
                        editingCoreName={editingCoreName}
                        onEditStart={handleEditCoreName}
                        onEditChange={setEditingCoreName}
                        onEditCancel={handleCancelEdit}
                        onEditSave={() => handleSaveCoreName(device.id)}
                        onKeyDown={(e) => handleKeyPress(e, device.id)}
                        canEdit={canEditCoreName}
                        canDelete={canDeleteCore}
                        onDelete={handleDeleteCore}
                      />
                    </div>
                  </h3>

                  <div style={{ marginLeft: 4 }}>
                    <Button
                      modifier="as-link"
                      onClick={toggleDropDown}
                      style={{
                        fontSize: 16,
                        textDecoration: 'underline',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      Show details
                      {onDropDown ? (
                        <IcoUp style={{ width: 12, height: 12 }} />
                      ) : (
                        <IcoDown style={{ width: 12, height: 12 }} />
                      )}
                    </Button>
                  </div>
                </div>
                <Toggle>
                  {({ on, toggle }) => (
                    <>
                      <Button
                        modifier="as-link"
                        onClick={toggle}
                        style={{
                          fontSize: 16,
                          marginRight: 5,
                          marginTop: 5,
                        }}
                      >
                        Edit streams
                      </Button>
                      <QsysModal
                        on={on}
                        toggle={toggle}
                        zones={device.zones || []}
                        deviceId={device.id}
                        noOfZones={device.noOfZones}
                      />
                    </>
                  )}
                </Toggle>
              </div>
              {onDropDown && <CoreShowDetails device={device} />}
              <div className="devices">
                <div className="devices__head" style={{ marginBottom: 20 }}>
                  <div className="subtitle" style={{ marginTop: 0 }}>
                    <h4 style={{ fontSize: 16 }}>
                      Active streams: {device.zones?.length || 0}
                    </h4>
                  </div>
                </div>

                <div className="devices__body">
                  {device.zones?.map((zone, index) => (
                    <div
                      key={zone?.zoneId}
                      className="device"
                      style={{
                        backgroundColor:
                          zone?.reason === 'play'
                            ? getRandomColor(index % 15)
                            : 'grey',
                      }}
                    >
                      <Button
                        to={{
                          pathname: `/qsys/core/${device.id}/zone/${zone?.zoneId}`,
                        }}
                        className="device__link"
                      />

                      <div className="device__head">
                        <h3>{zone?.zoneName}</h3>
                      </div>

                      <div className="device__body">
                        <h4>{zone?.currentTitle}</h4>
                        <h5>{zone?.currentArtist}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Toggle>
      ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          marginTop: 90,
          paddingBottom: 50,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>Need help?</p>
        <p>
          Visit{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://support.activaire.com/q-sys"
          >
            https://support.activaire.com/q-sys
          </a>
        </p>
      </div>

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={cancelDelete}
        onConfirm={confirmDelete}
        coreName={
          coreToDelete?.name ||
          `Core ${qsysDevices.findIndex((d) => d.id === coreToDelete?.id) + 1}`
        }
      />
    </DashboardLayout>
  );
};

export default QsysZonesScreen;
